/**
 * PinImages is meant to offer an accessible way to change the pin images for result pin
 * on the interactive map page. Given some config, an SVG should be customizable to
 * have branding consistent styling in this file.
 */
class PinImages {
  /**
   * @param {Object} defaultPinConfig The configuration for the default pin
   * @param {Object} hoveredPinConfig The configuration for the hovered pin
   * @param {Object} selectedPinConfig The configuration for the selected pin
   */
  constructor(defaultPinConfig = {}, hoveredPinConfig = {}, selectedPinConfig = {}) {
    this.defaultPinConfig = defaultPinConfig;
    this.hoveredPinConfig = hoveredPinConfig;
    this.selectedPinConfig = selectedPinConfig;
  }

  /**
   * Generate standard theme pin given some parameters
   * @param {string} pin.backgroundColor Background color for the pin
   * @param {string} pin.strokeColor Stroke (border) color for the pin
   * @param {string} pin.labelColor Label (text) color for the pin
   * @param {number} pin.width The width of the pin
   * @param {number} pin.height The height of the pin
   * @param {string} pin.pinCount The index of the pin for the pin text
   * @return {Object} The SVG of the pin, and its width and height
   */
  generatePin ({
    backgroundColor = '#00759e',
    strokeColor = 'black',
    labelColor = 'white',
    width = 40,
    height= 47,
    index = '',
    profile = ''
  } = {}) {
    const svg = `
      <svg width="${width}" height="${height}" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 612 792" style="enable-background:new 0 0 612 792;" xml:space="preserve">
<style type="text/css">
	.st0{fill:none;}
	.st1{fill:#FFFFFF;}
</style>
<path class="st0" d="M306,328.3L306,328.3L306,328.3L306,328.3L306,328.3z" fill="${backgroundColor}"/>
<path d="M482.4,336.5c-5.2-87.6-74.8-158.6-162.4-164.9c-3.9-0.3-7.7-0.5-11.6-0.5c-56.8-1.1-104.3,21.6-140.5,67.1  c-53.5,67.3-50.8,159.6,4.6,225.2c30.9,36.6,61.8,73.2,92.8,109.8c13.5,15.8,26.9,31.7,40.8,48c1.4-1.6,2.4-2.7,3.4-3.8  c42.2-49.8,84-99.8,126.6-149.2C468.9,430.2,485.3,386.7,482.4,336.5z M350.5,441.3l-67.7-87c0,0-1.3-1.8-2.6-2v89h-18.6V266.8h18.6  v81.4c1.4,0,3.3-2.3,3.3-2.3l70.5-79.2h19.2l-76.7,78.2l78.5,96.3H350.5z" fill="${backgroundColor}"/>
<g>
	<path class="st1" d="M296.5,345.1l76.7-78.2H354L283.5,346c0,0-1.9,2.3-3.3,2.3v-81.4h-18.6v174.5h18.6v-89c1.3,0.2,2.6,2,2.6,2   l67.7,87h24.6L296.5,345.1z" fill="${backgroundColor}"/>
</g>
</svg>`;

      return { svg, width, height };
  };

  /**
   * Get the default pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getDefaultPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.defaultPinConfig.backgroundColor,
      strokeColor: this.defaultPinConfig.strokeColor,
      labelColor: this.defaultPinConfig.labelColor,
      width: 50,
      height: 54,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the hovered pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getHoveredPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.hoveredPinConfig.backgroundColor,
      strokeColor: this.hoveredPinConfig.strokeColor,
      labelColor: this.hoveredPinConfig.labelColor,
      width: 50,
      height: 58,
      index: '',
      profile: profile
    });
  }

  /**
   * Get the selected pin image
   * @param {Number} pinCount The pin index number for the pin label
   * @param {Object} profile The profile data for the entity associated with the pin
   */
  getSelectedPin (index, profile) {
    return this.generatePin({
      backgroundColor: this.selectedPinConfig.backgroundColor,
      strokeColor: this.selectedPinConfig.strokeColor,
      labelColor: this.selectedPinConfig.labelColor,
      width: 50,
      height: 58,
      index: '',
      profile: profile
    });
  }
}

export { PinImages };
